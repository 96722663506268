import React from "react";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet, isIPad13 } from "react-device-detect";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import postbox from "../../../images/blogs/postbox.png";
import "./NewsLetter.scss";


class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      successSubscribe: false,
      sending: false,
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(
      [
        this.title,
        this.subtitle,
        this.input,
        this.button,
      ],
      {
        y: 50,
        opacity: 0
      }
    );

    gsap.to(this.title, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.subtitle, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.input, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.button, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.9,
      duration: 1,
      ease: "power3"
    });

  }

  onEmailChange = (e) => {
    const value = e.target.value;
    this.setState({ email: value });
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email, {
      PATHNAME: '/blog'
    })
    if (result) {
      this.setState({
        successSubscribe: result.result == "success" ? true : false,
        sending: false
      });
      if (result.result == "success") {
        setTimeout(() => {
          this.setState({
            email: "",
            successSubscribe: false
          });
        }, 3000);
      }
    }
    // I recommend setting `result` to React state
    // but you can do whatever you want
  }


  render() {
    const { intl } = this.props;
    return (
      <div className="newsletter">
        <div className="newsletter_cont">
          <div className="newsletter_cont_leftCont">
            <h2 ref={(ref) => (this.title = ref)} className="newsletter_cont_leftCont_title">
              {intl.formatMessage({ id: "Blogs.Newsletter.Title" })}
            </h2>
            <div
              ref={(ref) => (this.subtitle = ref)}
              className="newsletter_cont_leftCont_subtitleWrapper">
              <p className="newsletter_cont_leftCont_description">
                {intl.formatMessage({ id: "Blogs.Newsletter.Subtitle" })}
              </p>
            </div>
            <form onSubmit={this._handleSubmit}>
              <div className="newsletter_cont_leftCont_inputWrapper" ref={(ref) => (this.input = ref)}>
                <label> {intl.formatMessage({ id: "Blogs.Newsletter.Input-label" })}</label>
                <input type="email" placeholder={intl.formatMessage({ id: "Blogs.Newsletter.Input-placeholder" })} value={this.state.email} onChange={this.onEmailChange} />
              </div>
              <div className="newsletter_cont_leftCont_buttonCont">
                <button
                  ref={(ref) => (this.button = ref)}
                  type="submit"
                  className="button button-transparent newsletter_cont_leftCont_button"
                  style={{ background: this.state.successSubscribe ? "#dafae7" : "unset" }}>
                  <p>{this.state.sending ? "Sending..." : this.state.successSubscribe ? "Subscribed!" : intl.formatMessage({ id: "Blogs.Newsletter.Button" })}</p>
                </button>
              </div>
            </form>
          </div>
          <div className="newsletter_cont_rightCont">
            <img
              ref={(ref) => (this.greenImage = ref)}
              className="newsletter_cont_rightCont_image"
              src={postbox}
              alt={"aircrex clever subscribe"}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Newsletter);
