import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import logo from "../../images/header/clever-icon.png";
import ScrollTrigger from "gsap/ScrollTrigger";
import AppDownloadButton from "../AppDownloadButton/AppDownloadButton";
import "./GetCleverComponent.scss";

class GetCleverComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set([this.getCleverComponent], {
      y: 50,
      opacity: 0,
      transform: "scale(0.5)"
    });
    gsap.to(this.getCleverComponent, {
      scrollTrigger: this.getCleverComponent,
      start: "top 80%",
      opacity: 1,
      transform: "scale(1)",
      delay: 0,
      duration: 0.5,
      ease: "power3"
    });
  }

  componentWillUnmount() {}

  render() {
    const { intl } = this.props;
    return (
      <div ref={(ref) => (this.getCleverComponent = ref)} className="getCleverComponent">
        <img alt="clever logo" src={logo} />
        {(isBrowser || isTablet) && <span>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.FooterText" })}</span>}
        {isMobile && !isTablet && (
          <span>
            {intl.formatMessage({ id: "Homepage.HowCleverpayWorks.FooterText1" })}
            <br></br>
            {intl.formatMessage({ id: "Homepage.HowCleverpayWorks.FooterText2" })}
          </span>
        )}
        <AppDownloadButton />
      </div>
    );
  }
}
export default injectIntl(GetCleverComponent);
