import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import moment from "moment";
import "./BlogsGrid.scss";
import { convertStringToURL } from "../../../utils/utils";

export const getSubTitle = (data) => {
  const arr = data.split(".");
  return arr[0] || "";
};

const BlogItem = ({ item, index }) => {
  const formatDate = (date) => {
    var dateObj = moment(date, "YYYY-MM-DD hh:mm:ss");
    return dateObj.isValid() ? dateObj.format("MMMM DD, YYYY") : date;
  };
  return (
    <Link to={"/blog/" + String(convertStringToURL(item))} >
      <div id={"blogPost_" + index} className="blogsGrid_cont_blogsCont_blogPost">
        <div className="blogsGrid_cont_blogsCont_blogPost_imageCont">
          <img className="blogsGrid_cont_blogsCont_blogPost_imageCont_image" src={item.thumbnail} alt={"Hello Clever Blog"} />
        </div>
        <div className="blogsGrid_cont_blogsCont_blogPost_infoCont">
          <span className="blogsGrid_cont_blogsCont_blogPost_infoCont_author">{item.author || "Hello Clever"}</span>
          <span className="blogsGrid_cont_blogsCont_blogPost_infoCont_date">
            {formatDate(String(item.pubDate)) || new Date().toDateString()}
          </span>
        </div>
        <p className="blogsGrid_cont_blogsCont_blogPost_text">{item.title}</p>
      </div>
    </Link>
  );
};

class BlogsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.blogPosts,
      blogs: []
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    setTimeout(() => {
      this.animate();
    }, 300);
  }


  animate = () => {
    console.log("calling animate");
    this.state.items.forEach((post, index) => {
      gsap.set(["#blogPost_" + String(index)], {
        y: 50,
        opacity: 0
      });
    });
    this.state.items.forEach((post, index) => {
      gsap.to("#blogPost_" + String(index), {
        scrollTrigger: this.subtitle,
        start: "top 70%",
        opacity: 1,
        y: 0,
        delay: 0.2 * index,
        duration: 1,
        ease: "power3"
      });
    });
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="blogsGrid">
        <div className="blogsGrid_cont">
          <div className="blogsGrid_cont_blogsCont">
            {this.state.items.map((item, index) => {
              return <BlogItem key={item.id} item={item} index={index}></BlogItem>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(BlogsGrid);
