import * as React from "react";
import Layout from "../../components/layout";
import Hero from "./Hero/Hero";
import ColorfulCTAComponent from "../Common/ColorfulCTAComponent/ColorfulCTAComponent";
import { StickyContainer } from "react-sticky";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import BlogSection from "./BlogSection/BlogsGrid";
import NewsLetter from "./Newsletter/NewsLetter";

// markup
const Blog = (props) => {
    const intl = useIntl();
    const { blogPosts } = props.pageContext;
    return (
        <StickyContainer>
            <Layout>
                <Hero />
                <BlogSection blogPosts={blogPosts}/>
                <NewsLetter/>
                <ColorfulCTAComponent
                    title={intl.formatMessage({ id: "Blogs.Signup.Title" })}
                    subtitle={intl.formatMessage({ id: "Blogs.Signup.Subtitle" })}
                    button={intl.formatMessage({ id: "Blogs.Signup.Button" })}
                    link={"https://helloclever.typeform.com/enquiry"}
                    isAppDownload={false}
                />
            </Layout>
        </StickyContainer>
    );
};

export default Blog;