import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import lottieSliderData from "../../../lotties/Homepage/slider.json";

import "./Hero.scss";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    this.setupLotties();
    gsap.registerPlugin(ScrollTrigger);

    gsap.set([this.title, this.image1, this.image2, this.image3, this.image4, this.image5], {
      y: 50,
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.slider, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 0.5,
      ease: "power3"
    });
  }

  setupLotties = () => {
    this.sliderLottie = lottie.loadAnimation({
      container: this.slider,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: lottieSliderData
    });
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="blogsHero">
        <div className="blogsHero_cont">
          <div className="blogsHero_cont_textCont">
            <h1 ref={(ref) => (this.title = ref)}>{intl.formatMessage({ id: "Blogs.Hero.Title" })}</h1>
            <div ref={(ref) => (this.slider = ref)} className="blogsHero_cont_textCont_sliderLottie"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Hero);
