import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import GetCleverComponent from "../../../components/GetCleverComponent/GetCleverComponent";
import "./ColorfulCTAComponent.scss";

class ColorfulCTAComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      button: this.props.button,
      link: this.props.link,
      isAppDownload: this.props.isAppDownload ? this.props.isAppDownload : false
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set([this.title, this.subtitle, this.button], {
      y: 50,
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: { trigger: this.colorfulCTAComponentCont, start: "top 70%" },
      opacity: 1,
      y: 0,
      duration: 0.7,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: { trigger: this.colorfulCTAComponentCont, start: "top 70%" },
      opacity: 1,
      y: 0,
      duration: 0.7,
      ease: "power3"
    });
    gsap.to(this.button, {
      scrollTrigger: { trigger: this.colorfulCTAComponentCont, start: "top 70%" },
      opacity: 1,
      y: 0,
      duration: 0.7,
      ease: "power3"
    });
  }

  render() {
    return (
      <div ref={(ref) => (this.colorfulCTAComponentCont = ref)} className="colorfulCTAComponent">
        <div className="colorfulCTAComponent_cont">
          <h2 ref={(ref) => (this.title = ref)}>{this.state.title}</h2>
          <p ref={(ref) => (this.subtitle = ref)}>{this.state.subtitle}</p>
          {!this.state.isAppDownload && (
            <OutboundLink href={this.state.link} target="_blank">
              <div ref={(ref) => (this.button = ref)} className="button colorfulCTAComponent_cont_button">
                <p>{this.state.button}</p>
              </div>
            </OutboundLink>
          )}
          {this.state.isAppDownload && <GetCleverComponent />}
        </div>
        <div className="colorfulCTAComponent_fade"></div>
        <div className="colorfulCTAComponent_blurredShape colorfulCTAComponent_blurredShape-1"></div>
        <div className="colorfulCTAComponent_blurredShape colorfulCTAComponent_blurredShape-2"></div>
        <div className="colorfulCTAComponent_blurredShape colorfulCTAComponent_blurredShape-3"></div>
        <div className="colorfulCTAComponent_blurredShape colorfulCTAComponent_blurredShape-4"></div>
      </div>
    );
  }
}
export default ColorfulCTAComponent;
